<template>
	<div class="row patient_chart">
		<sidebar></sidebar>
		<div class="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 appointment_history allergies">
			<div class="a_header">
				<div>
					<div class="search">
						<img class="serch_icon" src="/images/search-icon.svg" alt="">
						<input type="text" @keyup="taskSearch" class="form-control" placeholder="Search Tasks" v-model="keyword" @blur="taskSearch" ref="globalSearch">
					</div>
					<span class="position-relative">
						<button class="comman_btn" @click="addFilter" v-if="this.filterList.length == 0"> Add Filter </button>
						<button class="comman_btn" @click="addFilter" v-else>Filters | {{ this.filterList.length }}</button>
						<CustomFilter :options="this.filterOptions" v-if="isShowCustomFilter" @custom-filter="handleCustomField" @refresh-data="getActiveTaskList" :selectedOpt="this.filterList"></CustomFilter>
					</span>
					<button class="comman_brdr_btn" v-if="this.selectedItems.length > 0" @click="completeTask('Completed')"> Complete </button>
					<button class="comman_brdr_btn text-red" v-if="this.selectedItems.length > 0" @click="deletedMultiTask()"> Delete </button>
				</div>
				<div>
					<button class="comman_btn" @click="addTask"> Add Tasks </button>
				</div>
			</div>

			<div class="table-responsive consult-list active-task-table">
				<table class="table">
					<thead>
						<tr>
							<th class="hover_unset w-50px">
								<input class="form-check-input" type="checkbox" v-model="selectAllChk" @change="checkSelectAll" :disabled="this.allActiveTasksList.length === 0">
							</th>
							<th scope="col" class="active" @click="changeSort('service_date', $event)"> 
								Create Date <img src="/images/down-arrow-new.svg" class="img-fluid">
							</th>
							<th scope="col" style="width:calc(var(--scale-ratio) * 480px);">
								Task Description / Updates <img src="/images/down-arrow-new.svg" class="img-fluid">
							</th>
							<th scope="col" @click="changeSort('patient_name', $event)"> 
								Demographic <img src="/images/down-arrow-new.svg" class="img-fluid">
							</th>
							<th scope="col" @click="changeSort('user_ids', $event)" style="width:calc(var(--scale-ratio) * 280px);"> 
								Assignees <img src="/images/down-arrow-new.svg" class="img-fluid">
							</th>
							<th scope="col" @click="changeSort('priority', $event)"> 
								Urgency <img src="/images/down-arrow-new.svg" class="img-fluid">
							</th>
							<th scope="col" class="hover_unset"></th>
						</tr>
					</thead>
					<tbody>
						<slot v-for="(item, index) in this.allActiveTasksList" :key="index">
							<tr :class="{ 'active': (selectedItems.includes(item.id)) }" :style="item.followup_list && item.followup_list.length > 0 ? 'border-top: 0.5px solid #4e6889;border-bottom: 0.5px solid transparent;' : 'border-top: 0.5px solid #4e6889;'">
								<td :rowspan="item.followup_list && item.followup_list.length > 0 ? item.followup_list.length + 1 : 1" style="border-bottom: 0.5px solid #4e6889;" class="first_checkbox">
									<input class="form-check-input" type="checkbox" :checked="isChecked(item.id)" @change="checkSelect(item.id)">
								</td>
								<td>
									{{ $filters.inputDateTime(item.formatted_created_at, 'YYYY-MM-DD') }}
								</td>
								<td style="padding-right: calc(var(--scale-ratio) * 60px);word-break: break-word;">{{ item.reminder_message }}</td>
								<td>{{ this.$filters.strToUpperCase(item.patient_name) }}</td>
								<td>{{ this.$filters.strToUpperCase(item.user_ids) }}</td>
								<td :style="{ color: item.priority == 'Urgent' ? '#DC1B3E' : '' }">{{ item.priority }}</td>
								<td><span class="text-decoration-underline cursor-pointer" @click="viewTask(item.id)">View</span></td>
								<td><span class="text-decoration-underline cursor-pointer" @click="deleteTask(item.id)">Delete</span></td>
							</tr>
							<tr v-for="(followup, followupIndex) in item.followup_list" :key="followupIndex"  :class="{ 'active': (selectedItems.includes(item.id)) }" class="multi-cments-table-tr">
								<td style="border: none;">{{ $filters.inputDateTime(followup.create_time, 'YYYY-MM-DD') }} </td>
								<td style="border: none;padding-right: calc(var(--scale-ratio) * 60px) !important;"> 
									> <span>({{ followup.last_name  }}, {{  followup.first_name  }})</span> <br>
									<span v-html="followup.description.replace(/\n/g, '<br>')"></span>
								</td>
								<td style="border: none;"></td>
								<td style="border: none;"></td>
								<td style="border: none;"></td>
								<td style="border: none;"></td>
								<td style="border: none;"></td>
							</tr>
						</slot>
					</tbody>
				</table>
			</div>
		</div>
	</div>

	<div class="modal fade" id="add-task-model" tabindex="-1" style="display: none;" aria-hidden="true" v-if="loadModel" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl add-task-model-box">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeAddTaskModel"></button>
					<div class="box">
						<div class="col-7 mx-auto">
							<h2 class="popup_title">{{ popupHeadingText }}</h2>
							<div class="position-relative z-index-1">
								<div class="d-flex justify-content-between">
									<div class="task-active-dropdown mb-10">
										<CustomDropDown :options="this.isCreate == true ? createStatus : updateStatus" :initialValue="form.status" v-model="form.status" @item-selected="handleStatusField" fieldName="Status" divClass="form-label"> </CustomDropDown>
									</div>
									<div class="task-priority-dropdown mb-10">
										<CustomDropDown :options="urgencyOpt" :initialValue="form.priority" v-model="form.priority" @item-selected="handlePriorityField" fieldName="priority" divClass="form-label"> </CustomDropDown>
									</div>
									<div class="associated-doc-line" v-if="this.selectedFiles.length > 0">
										<p class="associated-title">Associated Documents</p>
										<div class="attach-file-list">
											<a href="javascript:void:(0);" class="task-pdf-link d-block mb-20" target="_blank" v-if="form.document_id && document_type && document_from" @click="viewTaskDocument(form.document_id, form.patient_id)">{{ document_type }} | {{ document_from }} </a>
											<div class="file-item d-flex align-content-start mb-20" v-for="(fileDetail, index) in selectedFiles" :key="index">
												<span class="remove-file-icon cursor-pointer mr-15" @click="removeFileNew(index)">
													<img src="images/close-icon.svg">
												</span>
												<a :href="fileDetail.fileUrl" class="task-pdf-link" target="_blank">
													{{ fileDetail.fileName }}
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div>
								<div class="row align-items-end">
									<div class="col-4 col-sm-4 col-md-4 col-lg-4">
										<div class="input_box add-task-date-group mb-10 position-relative">
											<label class="form-label cursor-text l-h-n" for="typeunique_idX-2">Date</label>
											<div class="calendar _datepic demog-datepick-icon-remove">
												<ejs-datepicker class="e-field form-control" v-model="form.service_date" :max="currentDate" @change="onFieldChange('service_date', 'service_date_id2');" @blur="onFieldChange('service_date', 'service_date_id2')" :showClearButton="false" format="yyyy-MM-dd" @keyup="keyupdate('service_date', 'service_date_id2')" id="service_date_id2" ref="service_date_id2" :strictMode="true"></ejs-datepicker>
											</div>
											<div class="position-absolute l-h-n">
												<ValidationErrorMessageList :errors="v$.form.service_date.$errors" />
											</div>
										</div>
									</div>
									<div class="col-8 col-sm-8 col-md-8 col-lg-8">
										<div class="input_box task-demographic-search-box position-relative mb-10">
											<img class="serch_icon" src="/images/magnifying-glass-icon.svg" alt="">
											<input type="text" required="" id="typeunique_idX-21" class="form-control form-control-lg" @keyup="patientSearch" v-model="patient_search"  placeholder="Search Demographic">
											<div class="position-relative">
												<div class="search_drop_box" v-if="patientList.length > 0">
													<slot v-for="(item, index) in patientList" :key="index">
														<div class="con_drop_line" @click="selectPatient(item.patient_id, item.last_name + ', ' + item.first_name)">
															<div class="row px-2 align-items-center">
																<div class="col-md-12">
																	<div class="man_haeding">
																		{{ item.last_name }}, {{ item.first_name }} <span>({{ item.gender }})</span>
																	</div>
																	<ul>
																		<li>
																			DOB: {{ item.dob }}
																		</li>
																		<li>
																			HIN: {{ item.health_insurance_no }}
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</slot>
												</div>
											</div>
											<div class="position-absolute">
												<ValidationErrorMessageList :errors="v$.form.patient_id.$errors" />
											</div>
										</div>
									</div>
								</div>
								<div class="selact_dropdown task-provider-selct d-block h-auto mb-10" style="max-width: calc(var(--scale-ratio) * 420px);">
									<label class="form-label cursor-text fs-16 l-h-n fw-600">Assignee</label>
									<selectCheckBox :dataSource="provider_list" :labelName="tagProviderLabel" @clicked="selected_provider" :isShowDoctorIcon="false" :displayDiv="displayDiv" :key="select_box_key" :isUnselectAll="isUnselectAll"></selectCheckBox>
									<div class="l-h-n" style="margin-top: -5px;">
										<ValidationErrorMessageList :errors="v$.form.user_ids.$errors" />
									</div>
								</div>
								<div class="text_area mb-20 task-description-group">
									<label class="txt_label fw-600 fs-16 cursor-text">Task Description</label>
									<textarea class="form-control" rows="9" spellcheck="false" v-model="form.reminder_message"></textarea>
									<ValidationErrorMessageList :errors="v$.form.reminder_message.$errors" />
								</div>
								<div class="attach-files-group text-end mb-20">
									<button @click="triggerFileInput('fileInput')">
										<img src="/images/attach-file.svg" class="attach-file-icon">Attach Local Files..
									</button>
									<input type="file" id="fileInput" ref="fileInput" @change="handleFileChange($event,'add')" style="display: none;" accept="image/*,.pdf" multiple/>
								</div>
							</div>
						</div>
						<div class="text-center mt-30">
							<button class="comman_brdr_btn big_btn mx36" @click="closeAddTaskModel">Cancel</button>
							<button class="comman_btn big_btn mx36" @click="addTaskCall">Create	<img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" />
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="modal fade" id="task-details-model" tabindex="-1" style="display: none;" aria-hidden="true" v-if="loadModel" data-bs-backdrop="static" data-bs-keyboard="false">
		<div class="modal-dialog modal-dialog-centered modal-xl" style="max-width: calc(var(--scale-ratio) * 1600px);">
			<div class="modal-content">
				<div class="modal-body">
					<button type="button" class="btn-close popup_close" data-bs-dismiss="modal" aria-label="Close" @click="closeUpdateTaskModel"></button>
					<div class="box h-100">
						<div class="row m-0">
							<div class="col-8 col-sm-8 mx-auto">
								<div class="task-details-left position-relative">
									<h2 class="popup_title">{{ popupHeadingText }}</h2>
									<div class="position-relative z-index-1">
										<div class="d-flex justify-content-between">
											<div class="task-active-dropdown mb-10">
												<CustomDropDown :options="status" :initialValue="form.status" v-model="form.status" @item-selected="handleStatusField" fieldName="Status" divClass="form-label"></CustomDropDown>
											</div>
											<div class="task-priority-dropdown mb-10">
												<CustomDropDown :options="urgencyOpt" :initialValue="form.priority" v-model="form.priority" @item-selected="handlePriorityField" fieldName="priority" :divClass="form-label"></CustomDropDown>
											</div>
										</div>
										<div class="associated-doc-line">
											<p class="associated-title">Associated Documents</p>
											<div class="attach-file-list">
												<a href="javascript:void:(0);" class="task-pdf-link d-block mb-20" v-if="form.document_id && form.document_type && form.document_from" @click="viewTaskDocument(form.document_id, form.patient_id)">{{ form.document_type }} | {{ form.document_from }} </a>
												<div class="file-item d-flex align-content-start mb-20" v-for="(fileDetail, index) in this.exitingFiles" :key="index">
													<span class="remove-file-icon cursor-pointer mr-15" @click="removeFileExist(index, fileDetail.fileName)">
														<img src="images/close-icon.svg">
													</span>
													<a :href="fileDetail.fileUrl" class="task-pdf-link" target="_blank">
														{{ fileDetail.fileName }}
													</a>
												</div>
												<div class="file-item d-flex align-content-start mb-20" v-for="(fileDetail, index) in this.selectedFiles" :key="index">
													<span class="remove-file-icon cursor-pointer mr-15" @click="removeFileNew(index)">
														<img src="images/close-icon.svg">
													</span>
													<a :href="fileDetail.fileUrl" class="task-pdf-link" target="_blank">
														{{ fileDetail.fileName }}
													</a>
												</div>
											</div>
										</div>
									</div>
									<div class="">
										<div class="row">
											<div class="col-4 col-sm-4 col-md-4 col-lg-4">
												<div class="input_box add-task-date-group mb-10 position-relative">
													<label class="form-label cursor-text l-h-n" for="typeunique_idX-2">Date</label>
													<div class="calendar _datepic demog-datepick-icon-remove">
														<ejs-datepicker class="e-field form-control" v-model="form.service_date" :max="currentDate" @change="onFieldChange('service_date', 'service_date_id1');" @blur="onFieldChange('service_date', 'service_date_id1')" :showClearButton="false" format="yyyy-MM-dd" @keyup="keyupdate('service_date', 'service_date_id1')" id="service_date_id1" ref="service_date_id1" :strictMode="true" popup-open=true></ejs-datepicker>
													</div>
													<div class="position-absolute l-h-n">
														<ValidationErrorMessageList :errors="v$.form.service_date.$errors" />
													</div>
												</div>
											</div>
											<div class="col-8 col-sm-8 col-md-8 col-lg-8">
												<div class="input_box task-demographic-search-box position-relative mb-10 d-none">
													<img class="serch_icon" src="/images/magnifying-glass-icon.svg" alt="">
													<input type="text" required="" id="typeunique_idX-2" class="form-control form-control-lg" @keyup="patientSearch" v-model="patient_search" placeholder="Search Demographic">
													<ValidationErrorMessageList :errors="v$.form.patient_id.$errors" />
													<div class="position-relative">
														<div class="search_drop_box" v-if="patientList.length > 0">
															<slot v-for="(item, index) in patientList" :key="index">
																<div class="con_drop_line" @click="selectPatient(item.patient_id, item.last_name + ', ' + item.first_name)">
																	<div class="row px-2 align-items-center">
																		<div class="col-md-12">
																			<div class="man_haeding">
																				{{ item.last_name }}, {{ item.first_name }} <span>({{ item.gender }})</span>
																			</div>
																			<ul>
																				<li>
																					DOB: {{ item.dob }}
																				</li>
																				<li>
																					HIN: {{ item.health_insurance_no }}
																				</li>
																			</ul>
																		</div>
																	</div>
																</div>
															</slot>
														</div>
													</div>
												</div>
												<div class="patient_name_details float-end mt-35">
													<ul class="mb-0 p-0">
														<li>
															<p class="mb-0">Patient Name</p><span class="patnt-full_name text-decoration-underline text-uppercase" style="cursor: pointer;" @click="openMasterRecordPage($event, patient_detail.id)">{{ this.patient_detail.full_name }}</span> ({{ this.patient_detail.gender }})
														</li>
														<li>
															<p class="mb-0">Date of Birth </p>
															<span>{{ this.patient_detail.dob }} ({{ this.patient_detail.age_year }}) </span>
														</li>
														<li>
															<p class="mb-0">Cell Phone</p>
															<span>{{ this.patient_detail.cell_phone }}</span>
														</li>
													</ul>
												</div>
											</div>
										</div>
										<div class="selact_dropdown task-provider-selct" style="max-width: calc(var(--scale-ratio) * 420px);">
											<label class="form-label cursor-text l-h-n fs-16 fw-600">Assignee</label>
											<selectCheckBox :dataSource="provider_list" :labelName="tagProviderLabel" @clicked="selected_provider" :isShowDoctorIcon="false" :displayDiv="displayDiv" :key="select_box_key" :isUnselectAll="isUnselectAll"></selectCheckBox>
											<div class="l-h-n" style="margin-top: -5px;">
												<ValidationErrorMessageList :errors="v$.form.user_ids.$errors" />
											</div>
										</div>
										<div class="text_area mb-20 task-description-group">
											<label class="txt_label fw-600 fs-16 cursor-text">Task Description</label>
											<textarea class="form-control" rows="9" spellcheck="false" v-model="form.reminder_message"></textarea>
											<ValidationErrorMessageList :errors="v$.form.reminder_message.$errors" />
										</div>
										<div class="attach-files-group text-end mb-20">
											<button @click="triggerFileInput('fileInput1')">
												<img src="/images/attach-file.svg" class="attach-file-icon">Attach Local Files..
											</button>
											<input type="file" id="fileInput1" ref="fileInput1" @change="handleFileChange($event,'update')" style="display: none;" accept="image/*,.pdf" multiple />
										</div>
									</div>
									<div class="text-center task-details-btn">
										<button class="comman_brdr_btn big_btn mx36" @click="closeUpdateTaskModel"> Cancel
										</button>
										<button class="comman_btn big_btn mx36" @click="addTaskCall">
											Update
											<img src="images/loader.gif" v-if="loader" style="width: calc(var(--scale-ratio) * 18px);" />
										</button>
									</div>
								</div>
							</div>
							<div class="col-4 col-sm-4 mx-auto">
								<div
									class="task-comment-section d-flex flex-direction-column justify-content-between h-100">
									<div class="multi-coment-scroll" ref="commentContainer">
										<slot v-for="(item, index) in followupList" :key="index">
											<div class="provider_commented">
												<div class="commented-name-date d-flex mb-10">
													<p class="mb-0 mr-30">{{ item.creator }}</p>
													<p class="commented-date mb-0">{{ $filters.inputDateTime(item.create_time, "YYYY-MM-DD") }}</p>
												</div>
												<p class="commented-msg mb-0" v-html="item.description.replace(/\n/g, '<br>')"></p>
											</div>
										</slot>
									</div>
									<div>
										<div class="comment-textarea position-relative"
											:class="{ 'comment-textarea-active': commentBoxActive }">
											<textarea class="form-control" @focus="commentBoxActive = true" @blur="commentBoxActive = false" v-model="followupForm.description" rows="7" placeholder="Write a comment"></textarea>
											<p class="text-end mb-0">
												<button class="comment-add-btn" @click="storeTaskFollowup">Add</button>
											</p>
										</div>
										<div class="text-red position-absolute">
											<ValidationErrorMessageList :errors="v$.followupForm.description.$errors" />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import sidebar from './sidebar.vue';
import CustomDropDown from '../../base/formFields/CustomDropDown.vue';
import CustomFilter from '../../base/CustomFilter.vue';
import selectCheckBox from "@/components/base/selectCheckBox.vue";
import useVuelidate from "@vuelidate/core";
import axios from "@/scripts/axios.js";
import ValidationErrorMessageList from '../../base/ValidationErrorMessageList.vue';
import moment from "moment";
import $ from "jquery";
import { DatePickerComponent } from "@syncfusion/ej2-vue-calendars";

export default {
	setup: () => {
		return { v$: useVuelidate() };
	},
	components: {
		sidebar,
		CustomDropDown,
		CustomFilter,
		selectCheckBox,
		ValidationErrorMessageList,
		'ejs-datepicker': DatePickerComponent,
	},
	data() {
		return {
			loadModel: false,
			commentBoxActive: false,
			selectAllChk: false,
			selectedItems: [],
			allItems: [],
			urgencyOpt: [
				{ value: "Urgent", title: "Urgent" },
				{ value: "Normal", title: "Normal" },
			],
			status: [
				{ value: "Active", title: "Active" },
				{ value: "Completed", title: "Completed" },
				{ value: "Deleted", title: "Deleted" },
			],
			createStatus: [
				{ value: "Active", title: "Active" },
			],
			updateStatus: [
				{ value: "Active", title: "Active" },
				{ value: "Completed", title: "Completed" },
				{ value: "Deleted", title: "Deleted" },
			],
			filterOptions: [
				{ value: "received_timerange", title: "Create Date" },
				{ value: "reminder_message", title: "Task Description & Updates" },
				{ value: "patient_name", title: "Demographic" },
				{ value: "provider_name", title: "Assigned Provider" },
				{ value: "priority", title: "Urgency" },
			],
			isShowCustomFilter: false,
			allActiveTasksList: [],
			filterList: [],
			loader: false,
			popupHeadingText: "Add New Task",
			provider_list: [],
			displayDiv: false,
			filter: {
				provider_list: {},
			},
			document_from: '',
			document_type: '',

			patientList: [],
			tagProviderLabel: "Select User(s)",
			isUnselectAll: false,
			selectedFiles: [],
			exitingFiles: [],
			patient_detail: [],
			followupList: [],
			isCreate: false,
			allFiles: [],
			advanceFilter:[],
			keyword: ''
		}
	},
	async mounted() {
		this.$store.state.loader = true;
		this.resetTaskForm();

		this.filterAuthUser()

		this.getProviderList();
		await this.getActiveTaskList();
		document.addEventListener('click', this.handleClickOutside);

	},
	beforeUnmount() {
		// Clean up event listeners
		document.removeEventListener('click', this.handleClickOutside);
	},
	created(){
		this.$store.state.loader = true;
		const queryId = this.$route.query.id;
		if (queryId && Number(queryId) > 0) {
			setTimeout(() => {
				this.viewTask(Number(queryId))
			}, 1000);
		}
	},
	beforeCreate(){
		this.$store.state.loader = true;
	},
	methods: {
		initializeDatePicker(){
			var $this = this;
			$(document).on("click", '._datepic', function () {
				const inputField = $(this).find('input');
				const inputId = inputField.attr('id');
				$this.openDatePicker(inputId);
			});
		},
		openMasterRecordPage(event, patientId){
			event.stopPropagation();
			const url = this.$router.resolve({ name: 'PatientChart', params: { patient_id: patientId, } }).href;
			window.open(url, '_blank');
		},
		openDatePicker(refName) {
			var schObj = document.querySelector(`#${refName}`).ej2_instances[0];
			schObj.show();
		},
		isValidDate(dateString) {
			const [year, month, day] = dateString.split('-').map(num => parseInt(num, 10));
			const date = new Date(year, month - 1, day);
			return date.getFullYear() === year && (month === 0 || date.getMonth() + 1 === month) && (day === 0 || date.getDate() === day);
		},
		keyupdate(fieldName, fieldId) {
			if ($(`#${fieldId}-error`)) {
				$(`#${fieldId}-error`).text("");
			}
			const inputValue = $(`#${fieldId}`).val();
			var sanitizedInput = inputValue.replace(/[^\d]/g, '');
			if (sanitizedInput.length > 8) {
				sanitizedInput = sanitizedInput.substring(0, 8);
			}
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});
			$(`#${fieldId}`).val(formattedDate);
		},
		onFieldChange(fieldName, fieldId) {
			const sanitizedInput = $(`#${fieldId}`).val();
			var formattedDate = sanitizedInput.replace(/(\d{4})(\d{0,2})(\d{0,2})/, function (_, y, m, d) {
				let result = y;
				if (m) result += '-' + m;
				if (d) result += '-' + d;
				return result;
			});

			let errorElement = $(`#${fieldId}-error`);
			if (errorElement.length === 0) {
				errorElement = $(`<div id="${fieldId}-error" style="color: red;margin-top: 5px;"></div>`);
				$(`#${fieldId}`).parent().after(errorElement);
			}
			const isValidDate = (formattedDate != '') ? this.isValidDate(formattedDate) : false;
			if (isValidDate) {
				errorElement.text(''); // Clear error message if the date is valid
				this.form[fieldName] = moment(sanitizedInput).format('YYYY-MM-DD');
			} else {
				if (formattedDate != '') {
					errorElement.text('Invalid Date');
				}
				$(`#${fieldId}`).val("");
			}
		},
		refreshFollowupList(task_id) {
			axios.post("patient/task/list-followup", { task_id: task_id })
				.then((response) => {
					this.followupList = response.data.data;
					this.$nextTick(() => {
						this.scrollToBottom();
					});
				});
		},
		storeTaskFollowup(event) {
			event.preventDefault();
			const formName = "followupForm";
			this.v$[formName].$validate();
			if (!this.v$[formName].$error) {
				axios.post("patient/task/store-followup", this[formName])
					.then(() => {
						this.refreshFollowupList(this.followupForm.task_id);
						this.v$[formName].$reset();
						this[formName].description = null;
					});
			}
		},
		async getActiveTaskList() {
			this.$store.state.loader = true;
			this.form.from = 'ActiveTaskList';
			let patientId = null
			if(this.$route.name !== 'AllPatientActiveTaskList' && this.$route.name !== 'AllPatientCompleteDeleteTaskList'){
				patientId = this.$route.params.patient_id;
			}

			axios.post('patient/task/list', {
				from:this.form.from,
				sort_by:this.form.sort_by,
				sort_order:this.form.sort_order,
				advanceFilter:this.advanceFilter,
				keyword:this.keyword,
				patient_id:patientId
			})
				.then((response) => {
					this.allItems = []
					if (response.status == 200) {
						this.selectAllChk = false
						this.selectedItems = []
						this.allItems = []

						this.allActiveTasksList = response.data.data;
						if (this.allActiveTasksList) {
							this.allActiveTasksList = this.capitalizeData(this.allActiveTasksList);
						}
						this.allActiveTasksList.forEach(item => {
							this.allItems.push(item.id)
						});
						setTimeout(() => {
							this.$store.state.loader = false;
							this.loadModel = true;
						}, 500);
					} else {
						this.$store.state.loader = false;
						this.loadModel = true;
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.allItems = []
					this.$store.state.loader = false;
					this.loadModel = true;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else if(error.response.status === 402){
						window.location.href = error.response.data.redirect_url;
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		getProviderList() {
			axios.post("json_list/provider", { roleId: [0,1,2,3,4] })
				.then((response) => {
					this.provider_list = response.data.data.map((item) => {
						return { 
							id: item.user_id,
							name: item.full_name,
							checked: false,
							isFrom: 'task-module',
							roleName: item.role_name,
						};
					});
				});
		},
		patientSearch() {
			if (this.patient_search.length > 2) {
				axios.post("json_autocomplete/patient-search", { search_text: this.patient_search })
					.then((response) => {
						this.patientList = response.data.data;
					})
			}
			if (this.patient_search.length == 0) {
				this.patientList = [];
			}
		},
		selectPatient(id, patient_name) {
			this.form.patient_id = id;
			this.patient_search = patient_name;
			this.patientList = [];
		},
		handleClickOutside(event) {
			if (this.isShowCustomFilter && !event.target.closest('.comman_btn') && !event.target.closest('.search_drop_box') && !event.target.closest('.search') && event.target.closest('.a_header')) {
                    this.getActiveTaskList();
                    this.isShowCustomFilter = false;
                }

			const globalSearchElement = this.$refs.globalSearch;
			if (globalSearchElement && !globalSearchElement.contains(event.target)) {
				this.patientList = [];
			}
		},
		addTaskCall() {
			const formName = "form";
			this.v$[formName].$validate();
			if (this.v$[formName].$error) {
				for (const [key] of Object.entries(this.v$.form)) {
					if (this.v$.form[key].$errors && this.v$.form[key].$errors.length > 0) {
						const myEl = document.getElementById(key);
						if (myEl) {
							this.$smoothScroll({
								scrollTo: myEl,
							});
							break;
						}
					}
				}

				return true
			}

			const formData = new FormData();

			// Append the file to the FormData object
			if (this.selectedFiles && this.selectedFiles.length > 0) {
				
				this.selectedFiles.forEach((file, index) => {
					formData.append(`file[${index}]`, file.fileObject);
				});
				if (!this.validateTotalFieSize()){
					return false
				}
			}

			// Append the rest of the form data to the FormData object
			for (let key in this.form) {
				if (key != 'files') {
					if (typeof this.form[key] === 'object' && this.form[key] !== null) {
						for (let subKey in this.form[key]) {
							formData.append(`${key}[${subKey}]`, this.form[key][subKey]);
						}
					} else if (this.form[key]) {
						formData.append(key, this.form[key]);
					}
				}
			}

			const config = {
				headers: {
					'Authorization': `Bearer ${localStorage.getItem("authToken")}`,
					'Content-Type': 'multipart/form-data'
				},
			};

			this.loader = true;
			let url = 'patient/task/store'
			if (this.form.id) {
				url = 'patient/task/update'
			}

			axios.post(url, formData, config)
				.then((response) => {
					if (response.status == 200) {
						this.loader = false;
						if (!this.form.id) {
							this.closeAddTaskModel();
						} else {
							window.$("#task-details-model").modal("hide");
						}
						
						this.patient_search = '';
						this.filter.provider_list = {};
						this.exitingFiles = [];
						this.selectedFiles = [];

						// this.filterAuthUser()
						this.getProviderList();
						this.getActiveTaskList();
						
						this.$filters.moshaToast(response.data.message, "success");

						const queryId = this.$route.query.id;
						if (queryId && Number(queryId) > 0) {
							this.$router.push({ name: 'PatientEChartActiveTaskList', params: {patient_id: this.$route.params.patient_id} });
						}
						
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.loader = false;
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		viewTask(taskId) {
			this.$store.state.loader = true;
			this.popupHeadingText = "Task Details";
			this.initializeDatePicker();
			this.v$.followupForm.$reset();
			Object.assign(
				this.$store.state.tasks.followupForm,
				this.$store.state.tasks.defaultfollowupForm,
			);

			axios.post('patient/task/retrieve', { from_module: 'calendar', id: taskId })
				.then((response) => {
					if (response.data.status == 200) {
						if (this.$refs.fileInput1) {
							this.$refs.fileInput1.value = null;
						}
						const responseData = response.data.data;
						if (responseData) {
							this.$store.state.tasks.form = responseData
							this.patient_search = responseData.patient_name;
							this.fetchPatientDetail(responseData.patient_id)
							this.form.demographic = responseData.patient_name;
							this.document_type = responseData.document_type;
							this.document_from = responseData.document_from;
							this.exitingFiles = responseData.files;

							let assignedToIds = null;
							if (responseData.user_ids) {
								if (responseData.user_ids.includes(',')) {
									assignedToIds = responseData.user_ids.split(',').map(id => parseInt(id, 10));
								} else {
									assignedToIds = [parseInt(responseData.user_ids, 10)];
								}
							}

							this.form.user_ids = assignedToIds
							this.provider_list = this.provider_list.map(provider => {
								if (assignedToIds && assignedToIds.includes(provider.id)) {
									return { ...provider, checked: true };
								} else {
									return { ...provider, checked: false };
								}
							});

							if (this.form.user_ids && this.form.user_ids.length == 1) {
								this.tagProviderLabel = `${this.form.user_ids.length} User Selected`;
							} else if (this.form.user_ids && this.form.user_ids.length > 1) {
								this.tagProviderLabel = `${this.form.user_ids.length} Users Selected`;
							} else {
								this.tagProviderLabel = "Select User(s)";
							}
							this.followupForm.patient_id = responseData.patient_id;
							this.followupForm.task_id = responseData.id;
							this.refreshFollowupList(responseData.id);
							this.$store.state.loader = false;
							window.$("#task-details-model").modal("show");

						}
					} else {
						this.$store.state.loader = false;
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					this.$store.state.loader = false;
					console.log(error)
					if (error.response.data.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		viewTaskDocument(documentId,patient_id) {
			const route = this.$router.resolve({
				name: 'PatientEChartFilesEdit',
				params: { patient_id: patient_id, id: documentId }
			});

			window.open(route.href, '_blank');
		},
		completeTask(changeStatus) {
			this.$swal({
				title: 'Are you sure to COMPLETE this task?',
				text: '',
				icon: '',
				showCancelButton: true,
				confirmButtonColor: '#DD6B55',
				confirmButtonText: 'Complete',
				cancelButtonText: 'Cancel',
				closeOnConfirm: false,
				closeOnCancel: false,
				reverseButtons: true, // This will swap the Confirm and Cancel buttons
				customClass: {
					container: 'my-swal-container',
					popup: 'my-swal-popup delete-popup',
					header: 'my-swal-header',
					title: 'my-swal-title',
					closeButton: 'my-swal-close-button',
					icon: 'my-swal-icon',
					image: 'my-swal-image',
					content: 'my-swal-content',
					input: 'my-swal-input',
					actions: 'my-swal-actions',
					confirmButton: 'my-swal-confirm-button',
					cancelButton: 'my-swal-cancel-button',
					footer: 'my-swal-footer'
				}
			}).then((result) => {
				if (result.isConfirmed) {
					if (this.selectedItems) {
						axios.post("patient/task/update-status", { status: changeStatus, ids: this.selectedItems })
							.then((response) => {
								this.getActiveTaskList();
								this.selectedItems = []
								this.selectAllChk = false
								this.$filters.moshaToast(response.data.message, "success");
							});
					}
				}
			})
		},
		handleStatusField(values) {
			this.form.status = values.item.value;
		},
		handlePriorityField(values) {
			this.form.priority = values.item.value;
		},
		selected_provider(result) {
			this.provider_list = result;
			this.filter.provider_list = this.provider_list.map((item) => {
				return (item.checked == true) ? item.id : null;
			});
			this.filter.provider_list = this.filter.provider_list.filter(n => n);
			this.form.user_ids = this.filter.provider_list;

			if (this.form.user_ids.length == 1) {
				this.tagProviderLabel = `${this.form.user_ids.length} Users Selected`;
			} else if (this.form.user_ids.length > 1) {
				this.tagProviderLabel = `${this.form.user_ids.length} Users Selected`;
			} else {
				this.tagProviderLabel = "Select User(s)";
			}
		},
		deletedMultiTask(){
			this.$swal({
				title: 'Are you sure to DELETE these records?',
				text: '',
				icon: '',
				showCancelButton: true,
				confirmButtonColor: '#DD6B55',
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				closeOnConfirm: false,
				closeOnCancel: false,
				reverseButtons: true, // This will swap the Confirm and Cancel buttons
				customClass: {
					container: 'my-swal-container',
					popup: 'my-swal-popup delete-popup',
					header: 'my-swal-header',
					title: 'my-swal-title',
					closeButton: 'my-swal-close-button',
					icon: 'my-swal-icon',
					image: 'my-swal-image',
					content: 'my-swal-content',
					input: 'my-swal-input',
					actions: 'my-swal-actions',
					confirmButton: 'my-swal-confirm-button',
					cancelButton: 'my-swal-cancel-button',
					footer: 'my-swal-footer'
				}
			}).then((result) => {
				if (result.isConfirmed) {
					axios.post('patient/task/delete-multi', { 'ids': this.selectedItems })
						.then((response) => {
							if (response.data.status == 200) {
								this.selectedItems = []
								this.selectAllChk = false
								this.$filters.moshaToast(response.data.message, "success");
								this.getActiveTaskList();
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}).catch(error => {
							if (error.response.status === 422) {
								this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
							} else {
								this.$filters.moshaToast(error.response.data.message, "error");
							}
						});
				}
			});

		},
		deleteTask(id) {
			this.$swal({
				title: 'Are you sure to DELETE this records?',
				text: '',
				icon: '',
				showCancelButton: true,
				confirmButtonColor: '#DD6B55',
				confirmButtonText: 'Delete',
				cancelButtonText: 'Cancel',
				closeOnConfirm: false,
				closeOnCancel: false,
				reverseButtons: true, // This will swap the Confirm and Cancel buttons
				customClass: {
					container: 'my-swal-container',
					popup: 'my-swal-popup delete-popup',
					header: 'my-swal-header',
					title: 'my-swal-title',
					closeButton: 'my-swal-close-button',
					icon: 'my-swal-icon',
					image: 'my-swal-image',
					content: 'my-swal-content',
					input: 'my-swal-input',
					actions: 'my-swal-actions',
					confirmButton: 'my-swal-confirm-button',
					cancelButton: 'my-swal-cancel-button',
					footer: 'my-swal-footer'
				}
			}).then((result) => {
				if (result.isConfirmed) {
					axios.post('patient/task/delete', { 'id': id })
						.then((response) => {
							if (response.data.status == 200) {
								this.$filters.moshaToast(response.data.message, "success");
								this.selectAllChk = false
								this.getActiveTaskList();
							} else {
								this.$filters.moshaToast(response.data.message, "error");
							}
						}).catch(error => {
							if (error.response.status === 422) {
								this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
							} else {
								this.$filters.moshaToast(error.response.data.message, "error");
							}
						});
				}
			});
		},
		isChecked(id) {
			return this.selectedItems.includes(id);
		},
		checkSelectAll() {
			if (this.selectAllChk && this.allItems.length > 0) {
				this.selectedItems = [...this.allItems];
			} else {
				this.selectedItems = []
			}
		},
		checkSelect(id) {
            // Handle individual item selection/deselection
            const index = this.selectedItems.indexOf(id); // Find index of id in selectedItems
            if (index !== -1) {
                this.selectedItems.splice(index, 1); // Remove id from selectedItems
            } else {
                this.selectedItems.push(id)
            }

            // Update selectAllChk based on selectedItems length
            this.selectAllChk = this.selectedItems.length === this.allItems.length;
		},
		changeSort(orderColumnName, event) {
			event.stopPropagation();
			const th = event.target;

			this.form.sort_by = orderColumnName;
			if (th.classList.contains('active')) {
				th.classList.remove('active');
				this.form.sort_order = 'asc';
			} else {
				th.classList.add('active');
				this.form.sort_order = 'desc';
			}

			this.getActiveTaskList();
		},
		addTask() {
			this.isCreate = true;
			this.resetTaskForm();
			this.initializeDatePicker();
			// this.openDatePicker('service_date_id2');
			window.$("#add-task-model").modal("show");
			if(this.$route.params.patient_id){
				this.form.patient_id = this.$route.params.patient_id;
				this.fetchPatientDetail(this.$route.params.patient_id);
			}
		},
		closeAddTaskModel() {		
			// this.filterAuthUser()
			this.getActiveTaskList();
			window.$("#add-task-model").modal("hide");
			this.isCreate = false;
		},
		closeUpdateTaskModel() {
			window.$("#task-details-model").modal("hide");
			this.resetTaskForm();
			// this.filterAuthUser()
			this.getActiveTaskList();

		},
		resetTaskForm() {
			Object.assign(
				this.$store.state.tasks.form,
				this.$store.state.tasks.defaultFormData,
			);

			this.loader = false;
			this.document_type = '';
			this.document_from = '';
			this.tagProviderLabel = "Select User(s)"
			this.patient_search = ''
			this.v$.$reset();
			this.followupList = []
			this.exitingFiles = []
			this.provider_list = this.provider_list.map(provider => {
				return { ...provider, checked: false };
			});
			this.filter.provider_list = []
			this.form.patient_id = null;
			this.selectedFiles = []

			if (this.$refs.fileInput){this.$refs.fileInput.value = null;}
			if (this.$refs.fileInput1) {this.$refs.fileInput1.value = null;}

		},
		capitalizeData(data) {
			return data.map(item => ({
				...item,
				patient_name: this.$filters.strToUpperCase(item.patient_name),
				user_ids: this.$filters.strToUpperCase(item.user_ids)
			}));
		},
		taskSearch() {
			this.getActiveTaskList();
			// if (this.keyword.length > 2) {
			// 	this.getActiveTaskList();
			// }
			// if (this.keyword.length == 0) {
			// 	this.getActiveTaskList();
			// 	if (this.allActiveTasksList) {
			// 		this.allActiveTasksList = this.capitalizeData(this.allActiveTasksList);
			// 	}
			// }
		},
		addFilter() {
			this.isShowCustomFilter = !this.isShowCustomFilter;
		},
		handleCustomField(values) {
			this.filterList = values
			let advanceFilter = {}
			values.forEach(item => {
                let value = item.value
                if (item.value == 'Not Set') {
                    value = ''
                }
                advanceFilter[item.field] = value
            });
			this.advanceFilter = advanceFilter
		},
		triggerFileInput(refName) {
			this.$refs[refName].click();
		},
		handleFileChange(event,type) {
			const MAX_FILE_SIZE = 10 * 1000 * 1000; // 10 MB in bytes
			console.log(type)
			const files = Array.from(event.target.files); // Convert FileList to Array
			if (files.length > 0) {
				const newFiles = Array.from(event.target.files); // Convert FileList to Array

				newFiles.forEach((file) => {
					if (file.size > MAX_FILE_SIZE) {
						// Show a message or handle the error as needed
						this.$filters.moshaToast(`File "${file.name}" exceeds the 10 MB limit.`, "error")
						return;
					}

					let type = null
					if (file.type.startsWith('image/')) {
						type = 'image';
					} else if (file.type === 'application/pdf') {
						type = 'pdf';
					}

					this.selectedFiles.push({
						fileName: `${file.name}`,
						fileUrl: URL.createObjectURL(file),
						type: type,
						size: file.size,
						fileObject: file, // Store the actual file here
					});
				});

				this.$refs.fileInput.value = null;
				this.$refs.fileInput1.value = null;

				if (!this.validateTotalFieSize()){
					return false
				}
			}
		},
		removeFileExist(index, fileName) {
			this.exitingFiles.splice(index, 1);
			axios.post('patient/task/delete-file', {'id':this.form.id, 'fileName': fileName })
				.then((response) => {
					if (response.data.status == 200) {
						this.$filters.moshaToast(response.data.message, "success");
					} else {
						this.$filters.moshaToast(response.data.message, "error");
					}
				}).catch(error => {
					if (error.response.status === 422) {
						this.$filters.moshaToast(Object.values(error.response.data.data).join(', '), "error");
					} else {
						this.$filters.moshaToast(error.response.data.message, "error");
					}
				});
		},
		removeFileNew(index){
			this.selectedFiles.splice(index, 1);
		},
		fetchPatientDetail(patientId) {
			axios.post("patient/detail", { 'id': patientId })
				.then((response) => {
					if (response.data.status === 200) {
						this.patient_detail = response.data.data;
						this.form.demographic = this.patient_detail.full_name;
						this.patient_search = this.patient_detail.full_name;
					} else {
						this.$filters.moshaToast(response.data.message, "error")
					}
				})
				.catch(error => {
					this.$filters.moshaToast(error.message, "error")
				});
		},
		scrollToBottom() {
			const container = this.$refs.commentContainer;
			container.scrollTop = container.scrollHeight;
		},
		validateTotalFieSize(){
			let totalFileSize = 0
			if (this.exitingFiles && this.exitingFiles.length > 0) {
				this.exitingFiles.forEach(item => {
					if (item.fileSize) {
						totalFileSize += item.fileSize
					}
				})
			}

			if (this.selectedFiles && this.selectedFiles.length > 0) {
				// Calculate the total size in bytes (15 MB = 15 * 1024 * 1024 bytes)
				const totalSize = this.selectedFiles.reduce((sum, file) => sum + file.size, 0);
				console.log(totalSize)
				const maxSize = 15 * 1000 * 1000; // 15 MB in bytes
				console.log(totalFileSize + totalSize)
				// Check if total size exceeds 15 MB
				if ((totalFileSize + totalSize) > maxSize) {
					this.$filters.moshaToast("The total size of the uploaded files exceeds 15 MB. Please reduce the size or number of files.", "error");
					return; // Stop the form submission
				}
			}

			return true
		},
		filterAuthUser(){
			const viewOpt = this.$route.query.view;
			const authUserRole = localStorage.getItem("authUserRole");
			if ((authUserRole === 'Doctor' || authUserRole === 'Nurse') && viewOpt != 'all') {
				const authUserName = localStorage.getItem('authUserName');
				this.filterList= [{field: "provider_name", value: authUserName}]
				this.handleCustomField(this.filterList)
			}

		}
	},
	computed: {
		form() {
			return this.$store.state.tasks.form;
		},
		defaultFormData() {
			return this.$store.state.tasks.defaultFormData;
		},
		followupForm() {
			return this.$store.state.tasks.followupForm;
		}
	},
	validations() {
		return {
			form: this.$store.state.tasks.validationRules.form,
			followupForm: this.$store.state.tasks.validationRules.followupForm,
		}
	},
	
}
</script>